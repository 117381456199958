import React from "react";
import Footer from "../Footer/Footer";
import MobileFooter from "../Footer/Mobile/MobileFooter";
import { GlobalStyle } from "../GlobalStyles";
import NavBar from "../NavBar/NavBar";

const Layout = ({ children }) => {
    return (
        <>
            <GlobalStyle />
            <NavBar />
            <div>{children}</div>
            <Footer />
            <MobileFooter />
        </>
    );
};

export default Layout;
