import React from "react";

import {
    SignUpButtonMain,
    SignUpButtonDiv,
    InsideButtonBorder,
    ButtonText,
} from "./SignUpButton.styles";

const SignUpButton = ({ submit, modal, onClick }) => {
    if (submit) {
        return (
            <SignUpButtonDiv>
                <InsideButtonBorder>
                    <ButtonText>SIGN UP</ButtonText>
                </InsideButtonBorder>
            </SignUpButtonDiv>
        );
    } else if (modal) {
        return (
            <SignUpButtonDiv onClick={onClick}>
                <InsideButtonBorder>
                    <ButtonText>OK</ButtonText>
                </InsideButtonBorder>
            </SignUpButtonDiv>
        );
    } else {
        return (
            <SignUpButtonMain to={"/signup"}>
                <InsideButtonBorder>
                    <ButtonText>SIGN UP</ButtonText>
                </InsideButtonBorder>
            </SignUpButtonMain>
        );
    }
};

export default SignUpButton;
