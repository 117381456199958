import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import bg from "../../../images/burger-menu.png";

const BurgerMenuStyles = {
    Container: styled.div`
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: url(${bg});
        background-size: cover;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        transform: ${(props) =>
            props.isOpen ? "translateY(0)" : "translateY(-100vh)"};
        transition: transform 0.5s ease-in-out;
        z-index: 2;
        @media (max-width: 900px) {
            display: flex;
        }
    `,

    LinksContainer: styled.div`
        margin-top: 50%;
        margin-bottom: 15%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        gap: 30px;
    `,

    Link: styled((props) => <Link {...props} />)`
        font-size: 1.6rem;
        color: white;
        text-transform: uppercase;
    `,
};

export default BurgerMenuStyles;
