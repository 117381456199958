import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import BackgroundGradient from "../../../images/Footer/bg.png";
import MobileBG from "../../../images/Footer/mobile_bg.png";

const MobileFooterStyles = {
    PictureContainer: styled.div`
        display: none;
        @media (max-width: 1350px) {
            display: inline;
            width: 100%;
        }
    `,

    Container: styled.div`
        display: none;
        width: 100%;
        background: url(${BackgroundGradient});
        background-position: 40% 50%;
        background-size: cover;
        height: max-content;
        padding: 100px 0px;
        flex-flow: column;
        align-items: flex-start;
        @media (max-width: 1350px) {
            display: flex;
            background-size: cover;
        }
        @media (max-width: 900px) {
            display: flex;
            background: url(${MobileBG});
            background-position: 20% 50%;
            background-size: cover;
            overflow: hidden;
        }
    `,

    TitlesContainer: styled.div`
        /* width: 1145px; */
        width: max-content;
        height: 30px;
        border-bottom: 1px solid gray;
        display: ${(props) => (props.small || props.medium ? "none" : "flex")};
        margin-left: 10%;
        margin-right: 10%;
        padding-left: 15px;
        @media (max-width: 980px) {
            display: ${(props) =>
                props.medium ? "flex" : props.small ? "none" : "flex"};
        }
        @media (max-width: 750px) {
            display: flex;
            margin-left: 5%;
        }
    `,

    TitleBox: styled.div`
        width: ${(props) => `${props.width}px` ?? "auto"};
        border-left: 1px solid gray;
        @media (max-width: 980px) {
            display: ${(props) => (props.large ? "none" : "inline")};
        }
        @media (max-width: 750px) {
            display: ${(props) =>
                props.medium || props.large ? "none" : "inline"};
        }
    `,

    TitleText: styled.div`
        position: relative;
        bottom: 50%;
        font-size: 1.5rem;
        color: #eefc57;
        margin-left: 10%;
    `,

    LinksContainer: styled.div`
        /* width: 1145px; */
        display: ${(props) => (props.small || props.medium ? "none" : "flex")};
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 10%;
        padding-left: 15px;
        margin-bottom: 50px;
        @media (max-width: 980px) {
            display: ${(props) =>
                props.medium ? "flex" : props.small ? "none" : "flex"};
        }
        @media (max-width: 750px) {
            display: flex;
            margin-left: 5%;
        }
    `,

    LinksBox: styled.div`
        width: ${(props) => `${props.width}px` ?? "auto"};
        border-left: 1px solid gray;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        @media (max-width: 980px) {
            display: ${(props) => (props.large ? "none" : "inline")};
        }
        @media (max-width: 750px) {
            display: ${(props) =>
                props.medium || props.large ? "none" : "flex"};
        }
    `,

    LinkSmallBoxContainer: styled.div`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 0px;
        width: 100%;
    `,

    LinkSmallBox: styled.div`
        width: 15px;
        border-bottom: 1px solid grey;
    `,

    LinkText: styled.div`
        font-size: 1.2rem;
        color: white;
        margin-left: 10px;
        width: 85%;
    `,

    LinkTextClickable: styled((props) => <Link {...props} />)`
        font-size: 1.2rem;
        color: white;
        margin-left: 10px;
        width: 85%;
        text-decoration: underline;
    `,
};

export default MobileFooterStyles;
