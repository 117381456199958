import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const NavBarStyles = {
    NavBarContainer: styled.div`
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5%;
        margin-top: 30px;
        z-index: 10;
        width: 100%;
    `,

    OakLogo: styled((props) => <Link {...props} />)`
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-left: 5%;
        width: 200px;

        & > img {
            width: 100%;
        }
    `,

    Navigation: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 350px;
        height: 100%;
        border-bottom: 1px solid grey;
        padding: 0% 0.5%;
        @media (max-width: 900px) {
            display: none;
        }
    `,

    NavigationLink: styled((props) => <Link {...props} />)`
        text-decoration: none;
        font-size: 1rem;
        color: white;
    `,

    VerticalLine: styled.div`
        border-left: 1px solid grey;
        height: 40px;
    `,

    ButtonContainer: styled.div`
        margin-right: 5%;
        @media (max-width: 900px) {
            display: none;
        }
    `,

    BurgerContainer: styled.button`
        display: none;
        @media (max-width: 900px) {
            display: flex;
            margin-right: 5%;
            background: none;
            border: none;
            cursor: pointer;
        }
    `,

    BurgerButton: styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 50px;
        height: 50px;
        background: transparent;
        border: none;
        padding: 0;
        z-index: 10;

        div {
            width: 49px;
            height: 6px;
            /* background: ${({ isActive }) => (isActive ? "#000" : "#fff")}; */
            background: #fff;
            border-radius: 10px;
            transition: all 0.5s ease-in-out;
            position: relative;
            transform-origin: 1px;

            :first-child {
                transform: ${({ isActive }) =>
                    isActive ? "rotate(45deg)" : "rotate(0)"};
            }

            :nth-child(2) {
                transform: ${({ isActive }) =>
                    isActive ? "scaleX(0)" : "scaleX(1)"};
            }

            :nth-child(3) {
                transform: ${({ isActive }) =>
                    isActive ? "rotate(-45deg)" : "rotate(0)"};
            }
        }
    `,
};

export default NavBarStyles;
