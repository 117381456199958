import { createGlobalStyle } from "styled-components";
import coolveticaNormal from "../fonts/coolvetica.otf";
import barcadeBrawl from "../fonts/barcade-brawl.ttf";
import "@fontsource/libre-franklin";

export const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap');
    @font-face {
        font-family: "Coolvetica";
        src: url(${coolveticaNormal})
    }
    @font-face {
        font-family: "Barcade";
        src: url(${barcadeBrawl})
    }
    html,
    body {
        padding: 0;
        margin: 0;
        scroll-behavior: smooth;
        font-family: "Libre Franklin", sans-serif;
    }
    a {
        text-decoration: none;
    }
    ul {
        margin: 0 auto;
        list-style-type: none;
    }
`;
