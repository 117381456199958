import React from "react";
import SignUpButton from "../../Button/SignUpButton";
import BurgerMenuStyles from "./BurgerMenu.styles";

const { Container, LinksContainer, Link } = BurgerMenuStyles;

const BurgerMenu = ({ burgerMenuOpen, setBurgerMenuOpen }) => {
    return (
        <Container
            isOpen={burgerMenuOpen}
            onClick={() => setBurgerMenuOpen((prev) => !prev)}
        >
            <LinksContainer>
                <Link to="/#about">About Us</Link>
                <Link to="/blog">Blog</Link>
                <Link to="/regulations">Regulations</Link>
            </LinksContainer>
            <SignUpButton />
        </Container>
    );
};

export default BurgerMenu;
