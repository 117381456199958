import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";

export const SignUpButtonMain = styled((props) => <Link {...props} />)`
    text-decoration: none;
    color: black;
    height: 70px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eefc57;
    clip-path: polygon(85% 0%, 100% 40%, 100% 100%, 0 100%, 0 0);
    /* box-shadow: 10px 5px 5px red; */
    &:hover {
        /* background-color: #f13c24; */
        background: linear-gradient(
            90deg,
            #ed1c24 0%,
            #f15a24 30%,
            #f15a24 60%,
            #ed1c24 100%
        );
    }
    &:active {
        transform: translate(5px, 8px);
    }
`;

export const SignUpButtonDiv = styled.div`
    text-decoration: none;
    color: black;
    height: 70px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eefc57;
    clip-path: polygon(85% 0%, 100% 40%, 100% 100%, 0 100%, 0 0);
    /* box-shadow: 10px 5px 5px red; */
    &:hover {
        /* background-color: #f13c24; */
        background: linear-gradient(
            90deg,
            #ed1c24 0%,
            #f15a24 30%,
            #f15a24 60%,
            #ed1c24 100%
        );
    }
    &:active {
        transform: translate(5px, 8px);
    }
`;

export const InsideButtonBorder = styled.div`
    background-color: black;
    height: 55px;
    width: 185px;
    clip-path: polygon(87% 0%, 100% 40%, 100% 100%, 0 100%, 0 0);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ButtonText = styled.div`
    font-family: "Barcade";
    font-size: 16px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(87.5% 0%, 100% 40%, 100% 100%, 0 100%, 0 0);
    height: 52px;
    width: 181px;
    background-color: #eefc57;
    ${SignUpButtonMain}:hover & {
        /* background-color: #f13c24; */
        background: linear-gradient(
            90deg,
            #ed1c24 0%,
            #f15a24 30%,
            #f15a24 60%,
            #ed1c24 100%
        );
    }
    ${SignUpButtonDiv}:hover & {
        /* background-color: #f13c24; */
        background: linear-gradient(
            90deg,
            #ed1c24 0%,
            #f15a24 30%,
            #f15a24 60%,
            #ed1c24 100%
        );
    }
`;

/* @keyframes glitch { */
const glitch = keyframes`
    0% {
     clip-path: var(--slice-1);
     transform: translate(-20px, -10px);
    }
   
    10% {
     clip-path: var(--slice-3);
     transform: translate(10px, 10px);
    }
   
    20% {
     clip-path: var(--slice-1);
     transform: translate(-10px, 10px);
    }
   
    30% {
     clip-path: var(--slice-3);
     transform: translate(0px, 5px);
    }
   
    40% {
     clip-path: var(--slice-2);
     transform: translate(-5px, 0px);
    }
   
    50% {
     clip-path: var(--slice-3);
     transform: translate(5px, 0px);
    }
   
    60% {
     clip-path: var(--slice-4);
     transform: translate(5px, 10px);
    }
   
    70% {
     clip-path: var(--slice-2);
     transform: translate(-10px, 10px);
    }
   
    80% {
     clip-path: var(--slice-5);
     transform: translate(20px, -10px);
    }
   
    90% {
     clip-path: var(--slice-1);
     transform: translate(-10px, 0px);
    }
   
    100% {
     clip-path: var(--slice-1);
     transform: translate(0);
    }
`;
