import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import BackgroundGradient from "../../images/Footer/bg.png";

const FooterStyles = {
    PictureContainer: styled.div`
        width: 100%;
        @media (max-width: 1350px) {
            display: none;
        }
    `,

    Container: styled.div`
        width: 100%;
        /* background: rgb(0, 0, 0);
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(238, 252, 87, 1) 350%
        ); */
        background: url(${BackgroundGradient});
        background-position: 30% 50%;
        background-size: cover;
        height: 450px;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        @media (max-width: 1350px) {
            display: none;
        }
    `,

    TitlesContainer: styled.div`
        width: 1145px;
        height: 30px;
        border-bottom: 1px solid gray;
        display: flex;
        margin-left: 10%;
        margin-right: 10%;
        padding-left: 15px;
    `,

    TitleBox: styled.div`
        width: ${(props) => `${props.width}px` ?? "auto"};
        border-left: 1px solid gray;
    `,

    TitleText: styled.div`
        position: relative;
        bottom: 50%;
        font-size: 1.5rem;
        color: #eefc57;
        margin-left: 10%;
    `,

    LinksContainer: styled.div`
        width: 1145px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 10%;
        padding-left: 15px;
    `,

    LinksBox: styled.div`
        width: ${(props) => `${props.width}px` ?? "auto"};
        border-left: 1px solid gray;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
    `,

    LinkSmallBoxContainer: styled.div`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 0px;
        width: 100%;
    `,

    LinkSmallBox: styled.div`
        width: 15px;
        border-bottom: 1px solid grey;
    `,

    LinkText: styled.div`
        font-size: 1.2rem;
        color: white;
        margin-left: 10px;
        width: 85%;
    `,

    LinkTextClickable: styled((props) => <Link {...props} />)`
        font-size: 1.2rem;
        color: white;
        margin-left: 10px;
        width: 85%;
        text-decoration: underline;
    `,
};

export default FooterStyles;
