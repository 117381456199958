import React from "react";
import FooterStyles from "./Footer.styles";
import { StaticImage } from "gatsby-plugin-image";

const {
    Container,
    PictureContainer,
    TitlesContainer,
    TitleBox,
    TitleText,
    LinksContainer,
    LinksBox,
    LinkSmallBoxContainer,
    LinkSmallBox,
    LinkText,
    LinkTextClickable,
} = FooterStyles;

const Footer = () => {
    return (
        <>
            <PictureContainer>
                <StaticImage
                    src={"../../images/Footer/footer.png"}
                    alt="Footer img"
                    placeholder="blurred"
                    layout="fullWidth"
                />
            </PictureContainer>
            <Container>
                <TitlesContainer>
                    <TitleBox width={200}>
                        <TitleText>QUICK LINKS</TitleText>
                    </TitleBox>
                    <TitleBox width={300}>
                        <TitleText>OFFICE POLAND</TitleText>
                    </TitleBox>
                    <TitleBox width={380}>
                        <TitleText>AUSTRIA</TitleText>
                    </TitleBox>
                    <TitleBox width={250}>
                        <TitleText>NORDIC</TitleText>
                    </TitleBox>
                </TitlesContainer>
                <LinksContainer>
                    <LinksBox width={200}>
                        <LinkSmallBoxContainer>
                            <LinkSmallBox />
                            <LinkTextClickable to={"/#about"}>
                                About Us
                            </LinkTextClickable>
                        </LinkSmallBoxContainer>
                        <LinkSmallBoxContainer>
                            <LinkSmallBox />
                            <LinkTextClickable to={"/blog"}>
                                Blog
                            </LinkTextClickable>
                        </LinkSmallBoxContainer>
                        <LinkSmallBoxContainer>
                            <LinkSmallBox />
                            <LinkTextClickable to={"/regulations"}>
                                Regulations
                            </LinkTextClickable>
                        </LinkSmallBoxContainer>
                        <LinkSmallBoxContainer>
                            <LinkSmallBox />
                            <LinkTextClickable to={"/signup"}>
                                Sign Up
                            </LinkTextClickable>
                        </LinkSmallBoxContainer>
                    </LinksBox>
                    <LinksBox width={300}>
                        <LinkSmallBoxContainer>
                            <LinkSmallBox />
                            <LinkText>
                                Żmigrodzka 244, 51-131 Wrocław, Poland
                            </LinkText>
                        </LinkSmallBoxContainer>
                        <LinkSmallBoxContainer>
                            <LinkSmallBox />
                            <LinkText>office@oakfusion.pl</LinkText>
                        </LinkSmallBoxContainer>
                        <LinkSmallBoxContainer>
                            <LinkSmallBox />
                            <LinkText>+48 797 743 064</LinkText>
                        </LinkSmallBoxContainer>
                    </LinksBox>
                    <LinksBox width={380}>
                        <LinkSmallBoxContainer>
                            <LinkSmallBox />
                            <LinkText>
                                c/o Impact Hub Vienna Lindengasse 56/18-19 1070
                                Wien, Austria
                            </LinkText>
                        </LinkSmallBoxContainer>
                        <LinkSmallBoxContainer>
                            <LinkSmallBox />
                            <LinkText>vienna@oakfusion.com</LinkText>
                        </LinkSmallBoxContainer>
                        <LinkSmallBoxContainer>
                            <LinkSmallBox />
                            <LinkText>+43 69919064248</LinkText>
                        </LinkSmallBoxContainer>
                    </LinksBox>
                    <LinksBox width={250}>
                        <LinkSmallBoxContainer>
                            <LinkSmallBox />
                            <LinkText>
                                Anckargripsgatan 3 211 19-SE Malmö Sweden
                            </LinkText>
                        </LinkSmallBoxContainer>
                        <LinkSmallBoxContainer>
                            <LinkSmallBox />
                            <LinkText>office@oakfusion.pl</LinkText>
                        </LinkSmallBoxContainer>
                    </LinksBox>
                </LinksContainer>
            </Container>
        </>
    );
};

export default Footer;
