import React, { useState, useEffect } from "react";
import NavBarStyles from "./NavBar.styles";
import oakLogo from "../../images/logo.svg";
import SignUpButton from "../Button/SignUpButton";
import BurgerMenu from "./BurgerMenu/BurgerMenu";

const {
    NavBarContainer,
    Navigation,
    NavigationLink,
    OakLogo,
    VerticalLine,
    ButtonContainer,
    BurgerContainer,
    BurgerButton,
} = NavBarStyles;

const NavBar = () => {
    const [burgerMenuActive, setBurgerMenuActive] = useState(false);

    useEffect(() => {
        if (burgerMenuActive) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [burgerMenuActive]);

    return (
        <NavBarContainer>
            <OakLogo to={"/"}>
                <img src={oakLogo} alt="OakForge Logo" />
            </OakLogo>
            <Navigation>
                <VerticalLine />
                <NavigationLink to="/#about">ABOUT US</NavigationLink>
                <VerticalLine />
                <NavigationLink to="/blog">BLOG</NavigationLink>
                <VerticalLine />
                <NavigationLink to="/regulations">REGULATIONS</NavigationLink>
                <VerticalLine />
            </Navigation>
            <ButtonContainer>
                <SignUpButton />
            </ButtonContainer>
            <BurgerContainer
                onClick={() => setBurgerMenuActive((prev) => !prev)}
            >
                <BurgerButton isActive={burgerMenuActive}>
                    <div />
                    <div />
                    <div />
                </BurgerButton>
            </BurgerContainer>
            <BurgerMenu
                burgerMenuOpen={burgerMenuActive}
                setBurgerMenuOpen={setBurgerMenuActive}
            />
        </NavBarContainer>
    );
};

export default NavBar;
